import React from "react"
import pageHeadingStyle from "../components/pageHeading.module.css"
import pageContentListStyle from "../components/pageContentList.module.css"
import ArticleListItem from "../components/articleListItem"
import classNames from "classnames"

export default () => (
  <article>
    <div className={pageHeadingStyle.header}>
      <h2 className={pageHeadingStyle.header_title}>
        アクセシビリティ啓発活動
      </h2>
      <p className={pageHeadingStyle.header_description}>
        サイボウズのアクセシビリティに関する研修や勉強会の様子を公開しています
      </p>
    </div>
    <ol
      className={classNames(
        pageContentListStyle.article_list,
        pageContentListStyle.article
      )}
    >
      <ArticleListItem
        path="mobprogramming-with-blind-employee"
        title="社内勉強会「全盲社員とプログラミングする」"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/12/10"
        isTraining={true}
      />
      <ArticleListItem
        path="semantic-html-ux-writing"
        title="社内勉強会「セマンティックでアクセシブルなHTML講座（UXライティング編）」"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/10/28"
        isTraining={true}
      />
      <ArticleListItem
        path="semantic-html-basic-tag"
        title="社内勉強会「セマンティックでアクセシブルなHTML講座（基本タグ編）」"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/08/26"
        isTraining={true}
      />
      <ArticleListItem
        path="presentation-slide-archives"
        title="サイボウズの過去の社内勉強会資料"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/05/14"
        isTraining={true}
      />
      <ArticleListItem
        path="seminar-slide-archives"
        title="サイボウズが外部セミナーで発表した資料"
        tags={["アクセシビリティ啓発活動"]}
        date="2020/05/14"
        isTraining={true}
      />
    </ol>
  </article>
)
