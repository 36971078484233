import React from "react"
// import { Link } from "gatsby"
import Training from "../../components/training"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SecondPage = ({location}) => (
  <Layout path={location.pathname} >
    <SEO title="アクセシビリティ啓発活動" />
    <Training />
  </Layout>
)

export default SecondPage
